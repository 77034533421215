<template>
    <div>
        <header class="block-header"><i class="fa fa-camera"></i> Foto mailen</header>
        <input type="file" id="file" ref="file" accept="image/*" @change="handleImageFile">
        <label for="file">
            <span>Kies een foto</span>
        </label>

        <VoiceMemo @voice-memo-recorded="handleAudioFile"/>
    </div>
</template>

<script>
import Compressor from 'compressorjs'
import axios from '@/libraries/fetcher'
import { Notyf } from 'notyf'; // For error notifications
import 'notyf/notyf.min.css';
import VoiceMemo from '../components/VoiceMemo'

export default {
    name: 'SendItemToMail',
    components: { VoiceMemo, },
    data() {
        return {
            notyf: new Notyf(),
        }
    },
    methods: {
        handleImageFile(e) {
            const file = e.target.files[0];

            if (!file) {
                return;
            }

            // allowed types are tested on the server, images and pdf
            // for pdf, no compression needed
            if (file.type === 'application/pdf') {
                this.sendToServer(file)
            } else {
                var _this = this
                new Compressor(file, {
                    quality: 0.9,
                    maxWidth: 1024,
                    maxHeight: 1024,
                    minWidth: 300,
                    minHeight: 300,

                    // The compression process is asynchronous,
                    // which means you have to access the `result` in the `success` hook function.
                    success(result) {
                        _this.sendToServer(result)
                    },
                    error(err) {
                        _this.notyf.error(`Foto kon niet gecomprimeerd worden: ${err.message}`)
                    },
                });
            }
        },
        handleAudioFile(e) {
            console.log('handle audio file in parent component')
            console.log(e)
            this.sendToServer(e.memo, 'audio')
        },
        sendToServer(file, type = 'image') {
            const identifierInText = type === 'image' ? 'Foto' : 'Voice memo'
            const endpoint = type === 'image' ? '/api/upload/photo' : '/api/upload/voice-memo'
            const formData = new FormData();

            // The third parameter is required for server
            formData.append('file', file, file.name);

            axios.post(endpoint, formData)
            .then(() => {
                this.notyf.success(`${identifierInText} op weg naar mailbox`)
                if (type = 'image') {
                    this.$refs.file.value = null
                }
            })
            .catch(() => this.notyf.error(`${identifierInText} kon niet verstuurd worden`))
        },
    },
}
</script>

<style lang="less" scoped>
input[type=file] {
    width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}

input[type=file] + label {
    width: 100%;
    height: 144px;
    color: @textcolor;
    background-color: @background1;
    display: grid;
    place-items: center;

    span {
        text-decoration: underline;
    }
}

input[type=file]:focus + label,
input[type=file] + label:hover {
    background-color: @background2;
}
</style>