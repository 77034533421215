<template>
    <div>
        <header class="block-header"><i class="fa fa-microphone"></i> Voicememo mailen</header>
        <button v-if="!isRecording && !showPlayer" class="memo" @click="startRecording">
            <span>
                Memo starten
            </span>
        </button>

        <div v-if="isRecording" class="memo">
            <div id="player-container">
                <AudioViz />
                <button type="button" class="btn btn-primary" @click="stopRecording">
                    <i class="fa fa-stop"></i> Stop
                </button>
            </div>
        </div>

        <div v-if="showPlayer" class="memo">
            <div id="player-container">
                <audio id="player" :src="voiceMemoAudioUrl" controls></audio>
                <button type="button" class="btn btn-primary" @click="sendToServer">
                    <i class="fa fa-paper-plane"></i> Mail memo
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import AudioViz from './AudioViz.vue'

export default {
    name: 'VoiceMemo',
    components: {
        AudioViz
    },
    data() {
        return {
            showPlayer: false,
            isRecording: false,
            mediaRecorder: null,
            voicememo: null,
            activeAudioStream: null,
        }
    },
    computed: {
        voiceMemoAudioUrl() {
            if (this.voicememo === null) return null

            return URL.createObjectURL(this.voicememo)
        },
    },
    methods: {
        async startRecording() {
            const stream = await navigator.mediaDevices.getUserMedia({
                audio: true
            })
            
            this.createRecording(stream)
        },
        stopRecording() {
            this.mediaRecorder.stop()
        },
        createRecording(stream) {
            this.activeAudioStream = stream
            const options = {mimeType: 'audio/webm'};
            const recordedChunks = [];
            // const mediaRecorder = new MediaRecorder(stream, options);
            const mediaRecorder = new MediaRecorder(stream);

            mediaRecorder.addEventListener('dataavailable', e => {
                if (e.data.size > 0) {
                    console.log(e.data)
                    recordedChunks.push(e.data);
                }
            });

            mediaRecorder.addEventListener('stop', () => {
                this.isRecording = false
                // this.voicememo = new Blob(recordedChunks, { 'type' : 'audio/webm' })
                this.voicememo = new Blob(recordedChunks)
                this.showPlayer = true

                // remove red dot from tab
                this.activeAudioStream.getAudioTracks().forEach(track => {
                    track.stop()
                })
            });

            mediaRecorder.start();

            this.mediaRecorder = mediaRecorder
            this.isRecording = true
        },
        sendToServer() {
            this.$emit('voice-memo-recorded', {memo: this.voicememo})

            this.voicememo = null
            this.showPlayer = false
        },
    },
}
</script>

<style lang="less" scoped>
.memo {
    width: 100%;
    height: 144px;
    color: @textcolor;
    background-color: @background1;
    display: grid;
    place-items: center;
    border: none;

    span {
        text-decoration: underline;
    }
}

#player-container {
    display: grid;
    grid-gap: 12px;

    #player {
        height: 50px;
    }

    button {
        justify-self: center;
        padding: 12px;
    }
}
</style>