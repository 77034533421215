<template>
    <div id="audio-viz">
        <div id="1" class="block"></div>
        <div id="2" class="block"></div>
        <div id="3" class="block"></div>
        <div id="4" class="block"></div>
        <div id="5" class="block"></div>
        <div id="6" class="block"></div>
    </div>
</template>

<script>
export default {
    name: 'AudioViz',
    data() {
        return {
            interval: null
        }
    },
    mounted() {
        // init height for all blocks to a random height
        document.querySelectorAll('.block').forEach(element => {
            const height = Math.ceil(Math.random()*50)
            element.style.height=`${height}px`
        })

        // apply change using setInterval
        this.interval = setInterval(() => {
            document.querySelectorAll('.block').forEach(element => {
                const height = Math.ceil(Math.random()*50)
                element.style.height=`${height}px`
            })
        }, 300)
    },
    beforeDestroy() {
        clearInterval(this.interval)
    },
}
</script>

<style lang="less" scoped>
#audio-viz {
    display: grid;
    grid-template-columns: repeat(6, 12px);
    grid-gap: 0 1px;
    align-items: end;
    height: 60px;

    .block {
        box-sizing: border-box;
        border: 1px solid @secondary;
        background: @secondary;
    }
}
</style>